footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: none;
    justify-content:  space-between;
    align-items: center;
    z-index: 1000 !important;
    background: var(--bgColor);
}

@media only screen and (max-width: 767px) {
    footer{
        display: flex;
    }
    header .userItems .icon{
        display: none;
    }


    .About .AboutInfo {
        font-size: medium;
        max-width:max-content;
    }
    .About .AboutInfo p {
        position: relative;
        left:0;
    }




    
}
@media only screen and (max-height: 767px){
}.banner{
    justify-content:  center;
    align-items: center;
}

.menu {
    font-size: 1.8rem;
    color:#ffffff;
    text-shadow: 0px 0px 10px white, 0px 0px 15px var(--second);
}

footer .userItems {
    display: inline-flex;
    align-items:center;
    gap:20px;
    justify-content: center;
    width:100%;
    box-shadow: 5px 5px 15px rgba(255,255,255,0.1),-5px -5px 15px rgba(0,0,0,0.35);
}


