.imgBanner{
    position: relative;
    width: 100%;
    height:100%;
}

.imgBanner img {
    height: 300px; /* You can adjust this value as needed */
    width: 95%;
    margin: 65px 2.5%;
    margin-bottom: 15px;
    object-fit: cover;
    object-position: center;
    border-radius:  5px 30px 5px 30px !important;
    transition: 0.1s;
    box-shadow: 0px 0px 10px rgb(179, 179, 179), 0px 0px 15px var(--second);
    filter:opacity(1,0);
}

@media only screen and (max-width: 767px) {
    .imgBanner img {
        max-height:150px
        ;
    }
}
