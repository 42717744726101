lscreen {
    position: absolute;
    z-index: 9999;
    
    align-items: center;
    justify-content: center;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color: var(--bgColor);
    display: none;
}

lscreen img {
    width: 132px;
    height:132px;
}

lscreen.active {
    display: none;
}
@media only screen and (max-width: 767px) {
    lscreen {
        display: flex;
    }
    lscreen.active {
        display: none;
    }
}

.loader {
    position: absolute;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    display: inline-block;
    border-top: 4px solid var(--second);

    border-right: 4px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  .loader::after {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border-left: 4px solid white;
    border-bottom: 4px solid transparent;
    animation: rotation 0.5s linear infinite reverse;
  }
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 