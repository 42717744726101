header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content:  space-between;
    align-items: center;
    z-index: 1000 !important;
    background: var(--bgColor);
}

.menu {
    font-size: 1.8rem;
    color:#ffffff;
    text-shadow: 0px 0px 10px white, 0px 0px 15px var(--second);
}

.userItems {
    display: inline-flex;
    align-items:center;
    gap:20px;
    justify-content: end;
    width:100%;
    box-shadow: -5px -5px 15px rgba(255,255,255,0.1),5px 5px 15px rgba(0,0,0,0.35);
}

.userItems .title {
    position: absolute;
    padding: 10px;
    text-align: center;
    width:100%;
    right: 0;
    top:0;
    background-color: var(--bgColor);
    text-shadow: 0px 0px 10px white, 0px 0px 15px var(--second);
    box-shadow: 5px 5px 15px rgba(0,0,0,0.35);
}

.userItems .icon {
    position: relative;
    color: #ffffff;
    font-size: 1.5rem;
    width: 50px;
    height:50px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px var(--bgColor), 0px 0px 15px rgba(0,0,0,0.35);
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    text-shadow: 0px 0px 10px white, 0px 0px 15px var(--second);
    transition: 0.3s;
}

.userItems .icon img {
    max-width:28px;
    max-height: 28px;
    min-width: 16px;
    min-height: 16px;
    box-shadow: 0px 0px 10px var(--primary);
    

}

.userItems .icon:hover {
    background-color: var(--primary);
    box-shadow: 0px 0px 10px var(--primary), 0px 0px 15px rgba(0,0,0,0.35);
}


.userItems .avatar {
    width: 150px;
    height: 50px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    gap:15px;
    border-radius: 10px;
    box-shadow: -5px -5px 15px rgba(255,255,255,0.1), 5px 5px 15px rgba(0,0,0,0.35);
}

.userItems .avatar img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    box-shadow: 0px 0px 10px white, 0px 0px 15px var(--second);
    background-color: #ffffff;
}

.userItems .avatar a {
    color: #ffffff;
    font-size: 1.8rem;
}
.userItems .avatar .user {
    display: flex;
    flex-direction: column;
}

.userItems .avatar .user span {
    color: #ffffff;
    font-size: 0.7rem;
}

.userItems .avatar .user a {
    text-decoration: none;
    font-size: 0.6rem;
}