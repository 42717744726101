main {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--bgColor);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:30px;
    transition: 0.5s;
}

.banner {
    position: relative;
    margin-top: 40px;
    width: 100%;
    max-width: 100%;
    height:100%;
    background: var(--bgColor);
    display: none;
    transition: 1s;
    
}

.banner.active {
    display: block;
}

.About .AboutTitle{
    text-shadow: 0px 0px 10px white, 0px 0px 15px var(--second);
    text-align: center;
}
.About .AboutInfo img {
    margin-top: -50px;
    max-width: 100%;
}
.About .AboutInfo {
    display: flex;
    max-width: 100%;
    background: var(--primary);
    margin: 20px;
    border: 2px solid var(--primary);
    padding: 10px;
    flex-direction: column;
    border-radius: 5px 30px 5px 30px;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    text-shadow: 0px 0px 10px white, 0px 0px 15px var(--second);
    box-shadow: 0px 0px 15px var(--primary), 0px 0px 15px rgba(0,0,0,0.35);
}
.About .AboutInfo p {
    max-width: 500px;
    text-align: center;
}

section {
    overflow: hidden;
    position: absolute;
    width: 100%;
    top: 100vh;
    padding: 0 30px 0 30px;
    bottom: 0;
    opacity: 0;
    transition: ease-in-out 1s;
    transform-origin: bottom;
    z-index: 1000;
}


section.active {
    top: 100px;
    height: auto;
    overflow-y:  visible;
    opacity: 1;
}

